export const TRANSLATIONS_FR = {
    home: "Accueil",
    qui_sommes_nous: "Qui sommes-nous ?",
    sepro_solution: "SEPRO Solutions",
    sepro_technology: "SEPRO Technology",
    our_activities: "Nos activités",
    our_partners: "Nos références",
    contact_us: "Nous contacter",
    sepro: "SEPRO",
    solutions: "Solutions",
    technology: "Technology",
    home_msg_1: " le meilleur",
    home_msg_2: " choix pour",
    home_msg_3: " évoluer",
    home_msg_4: " sereinement",
    home_msg_7: " SEPRO holding est une société de droit sénégalais créée en 2015, regroupant deux entités: ",
    home_msg_5: "SEPRO solutions, spécialisée dans le développement de solutions informatiques.",
    home_msg_6: "SEPRO technology, spécialisée dans la mise en place d'infrastructures réseaux performants.",
    read_more: "En savoir plus",
    sepro_hoding: "SEPRO Holding",
    sepro_solution_msg_1: "Depuis sa création, SEPRO SOLUTIONS s’est appuyée sur un plan de\n" +
        "développement stratégique croissant et sur une vraie dynamique de conquête de\n" +
        "nouveaux marchés en diversifiant sans cesse sa gamme de prestation pour offrir\n" +
        "à ses clients et partenaires des services innovants, flexibles et sécurisés mais aussi des\n" +
        "produits robustes de qualité adaptés aux besoins de l’entreprise.",
    our: "Nos",
    services: "Services",
    sepro_solution_msg_2: "SEPRO SOLUTIONS développe également des solutions sur mesure avec des avantages concrets pour les entreprises désireuses de créer des solutions numériques performantes, autonomes et adaptées à leur contexte particulier." +
        " Cette approche libère des contraintes associées aux logiciels préfabriqués en proposant des solutions personnalisées qui répondent à vos enjeux spécifiques.",
    sepro_rh: " SEPRO RH",
    sepro_rh_subtitle: "L’information RH centralisée autour d’une plateforme unique",
    sepro_solution_msg_rh: "Vous offre un suivi clair et précis de votre entreprise ainsi que de ses employés avec son système de gestion WORKFLOW.",
    sepro_erp: " SEPRO ERP",
    sepro_solution_msg_erp: "Votre système d’information  de gestion  et de suivi quotidien  de l’ensemble des services opérationnels de l'entreprise.",
    sepro_athena: " Athena",
    sepro_solution_msg_athena: "Votre logiciel de gestion des établissements scolaires.",
    sepro_kayloo: " Kayloo",
    sepro_solution_msg_kayloo: "Votre première plateforme digitale de gestion immobilière.",
    sepro_service_app_mobile: "Applications mobiles",
    sepro_service_app_mobile_msg: "Nos ingénieurs développent des applications mobiles qui répondent à vos besoins ainsi qu'aux exigences des différents modèles de téléphones et tablettes  qui peuvent être déployées sur les systèmes d’exploitation IOS/Android et les applications web.",
    sepro_service_logiciels: "Logiciels de gestion d’entreprise (application WEB)",
    sepro_service_logiciels_msg1:  "Nous imaginons et créons des applications web pour vous accompagner dans la gestion de votre entreprise et vous rendre plus performant.",
    sepro_service_logiciels_msg2: "Nous développons des solutions métiers pour vous aider à gérer les actions internes suivantes : gestions des achats, ventes, facturation, stock, inventaire et la comptabilité générale et analytique etc.",
    sepro_service_logiciels_msg3: "Nous sommes là pour vous accompagner à chaque étape de la conception et développement de votre outil personnalisé. Ainsi, nous intervenons dès la naissance de votre projet mais également dans le cadre d’une refonte ou de la reprise d’un système déjà existant.",
 //   sepro_service_logiciels_msg2: " Nous imaginons et créons des applications web pour vous accompagner dans la gestion de votre entreprise et vous rendre plus performants. \n Nous développons des solutions métiers   pour vous aider à gérer les actions internes suivantes : gestions des achats, ventes, facturation, stock, inventaire et la comptabilité générale et analytique etc. Nous sommes là pour vous accompagner à chaque étape de la conception et développement de votre outil personnalisé. Ainsi, nous intervenons dès la naissance de votre projet mais également dans le cadre d’une refonte ou de la reprise d’un système déjà existant.",
   // sepro_service_logiciels_msg3: " Nous imaginons et créons des applications web pour vous accompagner dans la gestion de votre entreprise et vous rendre plus performants. \n Nous développons des solutions métiers   pour vous aider à gérer les actions internes suivantes : gestions des achats, ventes, facturation, stock, inventaire et la comptabilité générale et analytique etc. Nous sommes là pour vous accompagner à chaque étape de la conception et développement de votre outil personnalisé. Ainsi, nous intervenons dès la naissance de votre projet mais également dans le cadre d’une refonte ou de la reprise d’un système déjà existant.",

    sepro_service_site: "Conception et création de votre site web",
    sepro_service_site_msg: "Nos concepteurs et développeurs prennent le soin de vous connaitre vous et votre marché pour non seulement établir une relation de confiance mais aussi et surtout créer une présence nationale et internationale. Faites confiance à SEPRO et ses experts du markéting numérique pour la création de sites web à l’image de vos besoins et pour l’élaboration d’applications métier qui vous donneront l’envie de renouveler l’expérience chez nous.",
    sepro_technology_msg_1: "s’engage à gérer avec efficience toutes vos installations grâce à des procédés technologiques de pointe. La rigueur de notre approche et la qualité de nos services font de nous plus qu’un prestataire mais un partenaire pour nos clients.",
    sepro_telephonie: "Téléphonie d'entreprise",
    sepro_techno_msg_telephonie: "Nous vous proposons une offre de téléphonie d’entreprise clé comprenant les serveurs de téléphonie (IPBX-PABX), les postes (simples, wifi, portatifs et avancées), la configuration et la mise en service suivi d’un accompagnement pour vous délivrer un service sur mesure.",
    sepro_reseau_systeme: "Réseau et système d'entreprise",
    sepro_techno_msg_reseau_systeme: "Avec SEPRO Technology, bénéficiez d’un réseau d’entreprise performant et avantageux avec de la fourniture d’équipements réseaux robustes et sécurisés MICROTIK/UBIQUITI CISCO/FORTINET... \n" +
        "Faites-vous accompagner par une équipe d’ingénieurs et de techniciens certifiés et qualifiés pouvant conduire de grands projets.",
    sepro_electricity: "Électricité",
    sepro_techno_msg_electricity: "Sepro Technology intervient dans tout votre projet d’électrification et s’engage à mettre son savoir-faire dans la réalisation de vos travaux d'installation électrique de bâtiments à usage domestique et industriel selon les règles de sécurité allant du dimensionnement au raccordement, à la mise en service des équipements.",
    sepro_electricity_high: "Courant faible",
    sepro_electricity_low: "Courant fort",
    sepro_wifi: "Wifi d'entreprise",
    sepro_techno_msg_wifi_1: "Pour répondre au besoin de mobilité croissant de vos collaborateurs, Sepro vous accompagne pour déployer un réseau professionnel et vous assure une excellente qualité de service sans fil grâce à des algorithmes de gestion de la montée en charge, de priorisation de flux et bien plus encore.",
    sepro_techno_msg_wifi_2: "Sepro technonogy assure une disponibilité de services plus optimales à vos collaborateurs, peu importe leurs positions dans vos locaux.",
    sepro_techno_msg_wifi_3: "Assurez la connectivité de vos usagers en toute sécurité",
    sepro_techno_msg_wifi_4: "Bénéficiez d’une couverture Wi-Fi optimale",
    sepro_techno_msg_wifi_5: "Préservez l’efficacité et l’autonomie du service",
    sepro_techno_msg_wifi_6: "Développer votre réseau en toute facilité.",
    sepro_outsourcing: "Infogérance",
    sepro_techno_msg_outsourcing_1: "SEPRO en tant qu’interlocuteur et partenaire de proximité, propose aux entreprises désireuses d’externaliser totalement ou partiellement la gestion et l’exploitation de leurs parcs informatiques.",
    sepro_techno_msg_outsourcing_2: "Une offre sur mesure adaptée à votre organisation",
    sepro_techno_msg_outsourcing_3: "Maintenance préventive et curative",
    sepro_techno_msg_outsourcing_4: "Sauvegarde et sécurité des données",
    sepro_techno_msg_outsourcing_5: "Une mutualisation des ressources grâce à une surveillance active",
    sepro_techno_msg_outsourcing_6: "Une expertise technique",
    sepro_techno_msg_outsourcing_7: "Un contrôle et une supervision constant de votre parc informatique.",
    sepro_cybersecurity: "Cybersécurité",
    sepro_techno_msg_cybersecurity_1: "Avec Sepro optez pour un environnement sécuritaire et préservez l’intégrité de vos données tout en assurant la sécurité de vos systèmes informatiques.",
    sepro_techno_msg_cybersecurity_2: "Nous offrons entre autres :",
    sepro_techno_msg_cybersecurity_3: "Evaluation des risques et leurs impacts",
    sepro_techno_msg_cybersecurity_4: "Protection des données, (terminaux, postes et réseaux)",
    sepro_techno_msg_cybersecurity_5: "Confidentialité et intégralité des données",
    sepro_techno_msg_cybersecurity_6: "Analyse continue et monitoring,",
    sepro_techno_msg_cybersecurity_7: "Test d’intrusion et analyse de vulnérabilités.",
    sepro_techno_msg_cybersecurity_8: "Nous nous occupons de vos postes, votre infraréseau et de votre cloud.",
    sepro_techno_msg_cybersecurity_9: "Assurer la bonne maitrise des solutions par l’équipe opérationnelle.",
    ready_to_go: "Prêt à démarrer",
    one: "un",
    project: " projet",
    project_msg: "Vous avez un projet en infrastructures réseaux/télécom ou en développement de  solutions informatiques, Sepro Holding vous accompagne à atteindre vos objectifs. Nous vous proposons des offres sur mesure et adaptées à vos besoins",
    copyright: " Copyright SEPRO HOLDING. Tous droits réservés",
    customers: "Clients",
    partners: "Partenaires",
    sepro_kayloo_title_1: "Première plateforme de gestion immobilière du Sénégal",
    sepro_kayloo_msg_1: "De nombreux professionnels de la location ont déjà choisi d’adopter le logiciel KAYLOO et pour cause : Pourquoi ne pas se simplifier la vie?",
    sepro_kayloo_msg_11: "Parce que nous connaissons les contraintes imposées par les métiers de la gestion locative et du syndic de copropriété.",
    why: "Pourquoi",
    missions_de: "Les missions de ",
    discover_kayloo: "Découvrir kayloo",
    sepro_kayloo_msg_2: "Nous avons créé un logiciel totalement adapté à votre activité, et qui vous offre la possibilité de répondre au mieux aux attentes de vos clients. Simple d’utilisation, le logiciel KAYLOO dispose d’un outil de gestion intégré conforme aux exigences des particuliers et des professionnels du secteur de l’immobilier. Muni d’une interface accessible et conviviale, le logiciel kayloo dispose d’une gamme d’outils variés et faciles à maitriser :\n" +
        "Au-delà de son logiciel et de toutes ces fonctionnalités, kayloo se distingue par la disponibilité de son site d’annonce qui vous permettra de toucher une très large cible de clients et de collaborateurs.",
    sepro_kayloo_msg_3: "Lorem ipsum dolor sit amet. In dolore ratione a temporibus tempora id quibusdam dolores ad enim voluptas vel eius consequuntur ut dolorum eligendi est rerum sunt. Et galisum cumque vel enim itaque aut beatae consequatur non suscipit ullam. Non mollitia odit eos eligendi ullam eos asperiores possimus et nobis velit. Qui excepturi iure ut delectus inventore sit necessitatibus harum eos enim dolorum cum doloremque iste id quia enim sit aliquid corrupti.",
    mission_1: "Améliorer la qualité de l'offre immobilière",
    desc_maission_1: "Qui dolores fugiat qui omnis mollitia cum molestiae incidunt ut quae quae est quia ratione quo unde nihil. Qui voluptatibus officiis ut autem minima qui expedita mollitia eum perferendis odit quo voluptatem porro. A fugit voluptatibus eum eius beatae 33 illo autem vel dignissimos fugiat eos error cupiditate hic eligendi velit rem odit cupiditate. ",
    mission_2: "Promouvoir la collaboration entnre les différentes parties",
    desc_maission_2: "Qui dolores fugiat qui omnis mollitia cum molestiae incidunt ut quae quae est quia ratione quo unde nihil. Qui voluptatibus officiis ut autem minima qui expedita mollitia eum perferendis odit quo voluptatem porro. A fugit voluptatibus eum eius beatae 33 illo autem vel dignissimos fugiat eos error cupiditate hic eligendi velit rem odit cupiditate. ",
    mission_3: "Soutenir la vision et la mission de l'entreprise",
    desc_maission_3: "Qui dolores fugiat qui omnis mollitia cum molestiae incidunt ut quae quae est quia ratione quo unde nihil. Qui voluptatibus officiis ut autem minima qui expedita mollitia eum perferendis odit quo voluptatem porro. A fugit voluptatibus eum eius beatae 33 illo autem vel dignissimos fugiat eos error cupiditate hic eligendi velit rem odit cupiditate. ",
    mission_4: "Assurer la cohérence et l'homogénéité des informations",
    desc_maission_4: "Qui dolores fugiat qui omnis mollitia cum molestiae incidunt ut quae quae est quia ratione quo unde nihil. Qui voluptatibus officiis ut autem minima qui expedita mollitia eum perferendis odit quo voluptatem porro. A fugit voluptatibus eum eius beatae 33 illo autem vel dignissimos fugiat eos error cupiditate hic eligendi velit rem odit cupiditate. ",
    les_portails: "Les portails ",
    sepro_kayloo_msg_4: "Kayloo dispose de 3 espaces clients qui proposent à chaque type de client un contenu adapté et de manière sécurisée\n",
    espace: "Espace ",
    portail_1: "Bailleurs",
    portail_2: "Locataires",
    portail_3: "Bailleurs",
    portail_4: "Syndics",
    les: "Les",
    modules: "modules",
    disponibles: "disponibles",
    sepro_kayloo_msg_5: "Simple d’utilisation, le logiciel KAYLOO dispose d’un outil de gestion intégré conforme aux exigences des particuliers et des professionnels du secteur de l’immobilier.",
    sepro_kayloo_msg_7: "Muni d’une interface accessible et conviviale, le logiciel kayloo dispose d’une gamme d’outils variés et faciles à maitriser :",
    sepro_kayloo_msg_8: "Au-delà de son logiciel et de toutes ces fonctionnalités, kayloo se distingue par la disponibilité de son site d’annonce qui vous permettra de toucher une très large cible de clients et de collaborateurs.",
    module_1: "Gestion des biens",
    module_2: "Gestion des locataires",
    module_3: "Gestion des bailleurs",
    module_4: "Synndic et Copropriété",
    module_5: "Gestion des services",
    module_6: "Banque et Caisse",
    module_7: "Espace client",
    module_8: "Tableau de bord",
    property_management: " de gestion immobilière",
    management: "gestion",
    of_management: "de gestion",
    sepro_kayloo_msg_6: "La gestion immobilière autrement.",
    sepro_athena_title_1: "Logiciel de gestion scolaire",
    sepro_athena_msg_1: "Solution unique et globale de gestion des établissements scolaires, ATHENA est un progiciel de gestion scolaire capable de gérer tout le processus métier d’une école.",
    discover_athena: "Découvrir athena",
    sepro_athena_msg_2: "Développé par SEPRO Solutions, le progiciel ATHENA a pour objectif d’accompagner les établissements scolaires dans la gestion et le fonctionnement de leurs activités. Il permet à l’administration de l’établissement de mieux structurer ses tâches de la phase d’inscription à la sortie de l’apprenant.",
    sepro_athena_msg_3: "Le logiciel ATHENA est un outil indispensable pour la gestion de votre école. Les établissements scolaires peuvent désormais profiter des avancées technologiques pour simplifier, développer et moderniser leur fonctionnement avec notamment l’optimisation du temps de gestion, la dématérialisation des différents dossiers administratifs, une meilleure communication entre les différentes parties prenantes et la promotion de l’image de marque de l’établissement.",
    sepro_athena_msg_4: "ATHENA propose pour chaque profil une interface dédiée permettant à chaque acteur de consulter via son tableau de bord les informations essentielles dont il a besoin.",
    sepro_athena_msg_5: "Huit modules sont déployés de manière optimale pour une meilleure prise en charge de la gestion administrative.",
    sepro_athena_msg_6: "L'école autrement.",
    portail_athena_1: "Administration",
    portail_athena_2: "Apprenants",
    portail_athena_3: "Professeurs",
    portail_athena_4: "Parents",
    module_athena_1: "Inscription",
    module_athena_2: "Apprenant",
    module_athena_3: "Classes",
    module_athena_4: "Professeurs",
    module_athena_5: "Examens",
    module_athena_6: "Facturation",
    module_athena_7: "Services",
    module_athena_8: "Banque et caisse",
    mission_athena_1: "Améliorer la qualité de l'offre pédagogique",
    desc_mission_athena_1: "Qui dolores fugiat qui omnis mollitia cum molestiae incidunt ut quae quae est quia ratione quo unde nihil. Qui voluptatibus officiis ut autem minima qui expedita mollitia eum perferendis odit quo voluptatem porro. A fugit voluptatibus eum eius beatae 33 illo autem vel dignissimos fugiat eos error cupiditate hic eligendi velit rem odit cupiditate. ",
    mission_athena_2: "Promouvoir la collaboration entre les différentes parties",
    desc_mission_athena_2: "Qui dolores fugiat qui omnis mollitia cum molestiae incidunt ut quae quae est quia ratione quo unde nihil. Qui voluptatibus officiis ut autem minima qui expedita mollitia eum perferendis odit quo voluptatem porro. A fugit voluptatibus eum eius beatae 33 illo autem vel dignissimos fugiat eos error cupiditate hic eligendi velit rem odit cupiditate. ",
    mission_athena_3: "Soutenir la vision et la mission de l'institution",
    desc_mission_athena_3: "Qui dolores fugiat qui omnis mollitia cum molestiae incidunt ut quae quae est quia ratione quo unde nihil. Qui voluptatibus officiis ut autem minima qui expedita mollitia eum perferendis odit quo voluptatem porro. A fugit voluptatibus eum eius beatae 33 illo autem vel dignissimos fugiat eos error cupiditate hic eligendi velit rem odit cupiditate. ",
    mission_athena_4: "Assurer la cohérence et l'homogénéité des informations",
    desc_mission_athena_4: "Qui dolores fugiat qui omnis mollitia cum molestiae incidunt ut quae quae est quia ratione quo unde nihil. Qui voluptatibus officiis ut autem minima qui expedita mollitia eum perferendis odit quo voluptatem porro. A fugit voluptatibus eum eius beatae 33 illo autem vel dignissimos fugiat eos error cupiditate hic eligendi velit rem odit cupiditate. ",
    your_platform: "Votre plateforme",
    your_application: "Votre application",
    scolary_management: " de gestion scolaire",
    sepro_rh_title_1: "Application de gestion des ressources humaines",
    sepro_rh_title_2: " des ressources humaines",
    sepro_rh_msg_1: "Vous êtes une entreprise ou une association et vous souhaitez être accompagnée dans la gestion de vos Ressources Humaines ?\n" +
        "La plateforme SEPRO RH est à votre disposition pour vous informer et vous accompagner dans la gestion quotidienne des ressources humaines.",
    discover_rh: "Découvrir rh",
    why_sepro_rh_msg: "La plateforme SEPRO RH a pour vocation d’améliorer la qualité de travail entre vos collaborateurs, leurs managers et les différents responsables des Ressources Humaines.\n" +
        "Avec la plateforme l’ensemble des activités RH sont gérées depuis un portail unique et les échanges entre les  différents acteurs sont plus fluides.",
    mission_sepro_rh: "Nous vous offrons un système unifié et centralisé pour une utilisation optimale des processus RH.",
    sepro_rh_msg_6: "La gestion autrement.",
    gestion_rh: "de gestion des ressources humaines",
    module_rh_1: "Evaluations",
    module_rh_2: "Congés",
    module_rh_3: "Gestion du personnel",
    module_rh_4: "Recrutement",
    module_rh_5: "Banque de CV",
    module_rh_6: "Processus de recrutement",
    module_rh_7: "Fiche de poste",
    module_rh_8: "Formation",
    mission_rh_1: "Administrer et Organiser le Personnel au quotidien",
    desc_mission_rh_1: "",
    mission_rh_2: "Gérer et Accompagner le Changement incluant le développement des compétences",
    desc_mission_rh_2: "",
    mission_rh_3: "Renforcer la stratégie de l’Entreprise ",
    desc_mission_rh_3: "",
    mission_rh_4: "Assurer la cohérence et l'homogénéité des informations",
    desc_mission_rh_4: "",
    mission_rh_5: "Cibler les besoins présents de l’entreprise et rechercher des talents individuels capables de s’adapter aux besoins futurs de l’entreprise",
    desc_mission_rh_5: "",
    mission_rh_6: "Améliorer la productivité du capital humain et la performance de l’entreprise",
    desc_mission_rh_6: "",
    rh_management: " de gestion ",
    rh_management_2: " des ressources humaines ",

    sepro_erp_title_1: "Application de gestion",
    sepro_erp_title_2: " ERP",
    sepro_erp_msg_1: "Permet d’organiser l’activité de votre entreprise, d’éditer vos documents, de visualiser vos évènements, de lister vos fichiers ; de répertorier vos taches et de sauvegarder vos informations.",
    discover_erp: "Découvrir erp",
    sepro_erp_msg_2: " SEPRO ERP est une plateforme qui regroupe l’ensemble des composantes fonctionnelles de votre\n" +
        "entreprise en une seule base de données :",
    sepro_erp_msg_3:" Finance et facturation simplifiées et sans erreur : suivi des factures ,paiements et un rapprochement bancaire clair et précis." ,
    sepro_erp_msg_4:"Efficacité accrue dans la gestion du processus achat: demande d'achat,bon de commande fournisseur suivi du stock, approvisionnement et livraison.",
    sepro_erp_msg_5:"Une équipe de vente plus éfficace.",
    sepro_erp_msg_7:"Un suivi budgétaire clair.",
    sepro_erp_msg_8:"Reporting financier actualisé.",
    sepro_erp_msg_9:"Elle vous donne ainsi une vision globale de votre activité et permet une harmonisation de l’ensemble de votre système d’information.",
    sepro_erp_missions:"Avec SEPRO ERP, vous avez une source d’information unique, car l’ensemble des données provenant " +
        "de vos équipes sont regroupées dans une seule et même base de données ",
    sepro_module_dispo_text: "Six modules sont déployés pour une uniformisation de vos activités",
    portail_erp_1: "ERP 1",
    portail_erp_2: "ERP 2",
    portail_erp_3: "ERP 3",
    portail_erp_4: "ERP 4",
    sepro_erp_msg_6: "La gestion autrement.",
    module_erp_purchase: "GESTION DES VENTES",
    module_erp_shell: "GESTION DES ACHATS",
    module_erp_stock: "GESTION DE STOCK",
    module_erp_invoice: "FACTURATION",
    module_erp_budget: "GESTION DES BUDGETS",
    module_erp_project: "GESTION DE PROJECT",
    mission_erp_1: "Une meilleure gestion du temps",
    desc_mission_erp_1: "",
    mission_erp_2: "Uniformiser les informations et les opérations ",
    desc_mission_erp_2: "",
    mission_erp_3: "Partager en temps réel des informations communes au sein de l’entreprise ",
    desc_mission_erp_3: "",
    mission_erp_4: "Assurer la cohérence et l'homogénéité des informations",
    desc_mission_erp_4: " ",
    mission_erp_5: "La Traçabilité des Informations",
    desc_mission_erp_5: " ",
    mission_erp_6: "Rapidité dans la prise de décision",
    desc_mission_erp_6: " ",
    erp_management: " de gestion ",
    erp_management_2: " des ressources humaines ",
    contact: "Contact",
    put_fields_form_contact: "Veuillez remplir le formulaire ci-dessous",
    close: "Fermer",
    msg_qui_sommes_nous: "Depuis sa création la société développe une véritable politique d’innovation pour répondre aux besoins diversifiés de ses clients en développant plusieurs domaines d’activité stratégique.\n" +
        "Le développement de nos deux entités, nous permet de poursuivre une véritable politique d’innovation et de vous orienter vers des solutions compétitives et adaptables à votre réalité d’affaire.\n" +
        "La société a été agrée au fil des années par plusieurs entreprises bien cotées s grâce que savoir-faire dont elle fait preuve dans l’exécution de ses projets.",
    first_name: "Prénom(s)",
    last_name: "Nom",
    phone: "Téléphone",
    email: "Email",
    message: "Message",
    send: "Envoyer",
    address: "Adresse",
    capcha: "Captcha",
    newsletter: "Recevoir notre newsletter ",
    others_contact: "Autres moyens de nous contacter",
    email_sepro: "support@groupesepro.com",
    phone_sepro: "+221 33 867 15 25",
    address_sepro_1: "Liberté 6 Extension, ",
    address_sepro_2: "Villla: 6761 ",
    put_your_firstName: "Veuillez renseigner votre prénom(s)!",
    put_your_lastname: "Veuillez renseigner votre nom!",
    put_your_phone: "Veuillez renseigner votre numéro de téléphone!",
    put_your_email: "Veuillez renseigner votre adresse email!",
    put_your_message: "Veuillez renseigner votre message!",
    put_your_captcha: "Veuillez valider le captcha!",
    put_all_fields: "Veuillez renseigner tous les champs!",
    about: "A propos de nous",
    sepro_holding: "SEPRO holding",
    sepro_holding_about_1: "est une société de droit sénégalais créée en 2015 et regroupant deux entités:",
    sepro_holding_about_2: "Depuis sa création la société développe une véritable politique d’innovation pour répondre aux besoins diversifiés de ses clients en développant plusieurs domaines d’activité stratégique.",
    sepro_holding_about_3: "Le développement de nos deux entités, nous permet de poursuivre une véritable politique d’innovation et de vous orienter vers des solutions compétitives et adaptables à votre réalité d’affaire.",
    sepro_holding_about_4: "La société a été agrée au fil des années par plusieurs entreprises bien cotées grâce à sa savoir-faire dont elle fait preuve dans l’exécution de ses projets.",
    sepro_espace: "Espaces ",
    discover: "Découvrez ",
    le: "le ",
    groupe_sepro: "groupe sepro",
    spec_sepro_solution: "Spécialisée dans le développement de solutions informatiques",
    spec_sepro_tech: "Spécialisée dans la mise en place d'infrastructures réseaux performants",
    why_sepro:"Pourquoi SEPRO",
    about_bref_1:"Depuis sa création, la société développe une véritable politique d’innovation digitale devenue une réalité incontournable et un outil de croissance indispensable pour répondre aux besoins diversifiés des petites et moyennes entreprises.",
    about_bref_2:"Le développement de nos deux entités, nous permet d’établir des actions d’ouverture et de flexibilité afin de vous orienter vers des modes opératoires qui diffèrent, pour une vision claire et fixer des stratégies misées sur une démarche digitale pour promouvoir l’image de votre entreprise, booster votre croissance et rester compétitive.",
    about_bref_3:"La société a été agrée au fil des années par plusieurs entreprises bien cotées grâce au savoir-faire dont elle fait preuve dans l’exécution de ses projets.",
    nos_demarches: "Nos démarches",
    our_demarche: "Notre démarche",
    la_demarche_sepro: "La démarche SEPRO",

    agile: "Agile & Scrum",
    agile_1: " Une meilleure flexibilité.",
    agile_2: " Une meilleure relation avec les clients.",
    agile_3: " Une plus grande autonomie de l’équipe.",
    agile_4: " Des coûts mieux contrôlés.",
    agile_5: " Assurer des livraisons de haute qualité.",
    agile_6: " Générer un retour sur investissement plus élevé.",
    agile_7: " Accroître la satisfaction des utilisateurs.",

    started: "Démarrage",
    started_1: "Avoir un premier contact.",
    started_2: "Connaitre les différents acteurs du projet.",
    started_3: "Documenter la gouvernance du projet et valider le cahier de charge.",
    analyse: "Analyse des besoins",
    analyse_1: "Définir les cas d’utilisation qui permettront de mieux comprendre le périmètre du projet.",
    analyse_2: "Prendre le temps de faire une analyse du besoin et de la solution proposée pour éviter les écarts.",
    analyse_3: "Préparation des maquettes des modifications à apporter.",
    integration: "Intégration et développement",
    integration_1: "Installation des différents environnements du client.",
    formation: "Formation et Maintenance",
    formation_1: "Transfert de connaissances de SEPRO sur le produit vendu.",
    formation_2: "Définir le contrat de maintenance et de support applicatif.",
    test: "Test",
    test_1: "Définir le cahier de recette avec le client.            ",
    test_2: "Faire les tests de recette de l’application.             ",
    golive: "Go Live",
    golive_1: "Basculement en production",
    golive_2: "Activer la maintenance de notre logiciel.",
    sepro_mission_1: "Construire une confiance",
    sepro_mission_2: "L'excellence en toutes circonstances",
    sepro_mission_3: "Cultiver l'esprit d'entreprise",
    sepro_mission_4: "Faire la différence chaque jour",
    sepro_mission_5: "Les clients d'abord",
    sepro_mission_6: "Respect des engagements",
    valeurs: "valeurs",
    sepro_valeur_1: "Proximité",
    sepro_valeur_2: "Savoir-faire",
    sepro_valeur_3: "Qualité",
    sepro_valeur_4: "Engagement",
    sepro_valeur_5: "Responsabilité",
    sepro_valeur_6: "Confiance",
    the_modules: "Les modules disponibles",
    philosophy:"Réaliser des prestations de qualité dans une recherche constante de la satisfaction du client."
};