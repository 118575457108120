import React from "react";

export const video_url = '../../../static/videos/sepro.mp4'

export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export const activeMenu = (e) => {
    if (e) return 'text-cyan-600';
    else return '';
}

export const getOurTechnnologies = () => {
    return [



        {
            // icon: 'infogerence.svg',
            image: 'infogerence.jpg',
            title: 'sepro_outsourcing',
            paragraphes: [
                'sepro_techno_msg_outsourcing_1'
            ],
            lists: [
                'sepro_techno_msg_outsourcing_2',
                'sepro_techno_msg_outsourcing_3',
                'sepro_techno_msg_outsourcing_4',
                'sepro_techno_msg_outsourcing_5',
                'sepro_techno_msg_outsourcing_6',
                'sepro_techno_msg_outsourcing_7',
            ]

        },
        {
            //  icon: 'cybersecurite.svg',
            image: 'cybersecurite.jpg',
            title: 'sepro_cybersecurity',
            paragraphes: [
                'sepro_techno_msg_cybersecurity_1',
                'sepro_techno_msg_cybersecurity_2'
            ],
            lists: [
                'sepro_techno_msg_cybersecurity_3',
                'sepro_techno_msg_cybersecurity_4',
                'sepro_techno_msg_cybersecurity_5',
                'sepro_techno_msg_cybersecurity_6',
                'sepro_techno_msg_cybersecurity_7',
                'sepro_techno_msg_cybersecurity_8',
                'sepro_techno_msg_cybersecurity_9',
            ]

        },

    ]
}


export const getPartnersBis = () => {
    return [
        {
            image: 'cisco.jpg',
            name: 'CISCO',
            href: '#',
            labelledby: 'cisco-title',
        },
        {
            image: 'microsoft.jpg',
            name: 'Microsoft',
            href: '#',
            labelledby: 'microsoft-title',
        },
        {
            image: 'dlink.jpg',
            name: 'DLink',
            href: '#',
            labelledby: 'dlink-title',
        },
        {
            image: 'dell.jpg',
            name: 'DELL',
            href: '#',
            labelledby: 'dell-title',
        },
        {
            image: 'hp.jpg',
            name: 'HP',
            href: '#',
            labelledby: 'hp-title',
        },
        {
            image: 'lenovo.jpg',
            name: 'LENOVO',
            href: '#',
            labelledby: 'lenovo-title',
        },
        {
            image: 'epson.jpg',
            name: 'EPSON',
            href: '#',
            labelledby: 'epson-title',

        },

    ]
}

export const getMissionsKayloo = () => {
    return [
        {
            icon: 'icon1.svg',
            title: 'mission_1',
            text: 'desc_maission_1',
        },
        {
            icon: 'icon2.svg',
            title: 'mission_2',
            text: 'desc_maission_2',
        },
        {
            icon: 'icon3.svg',
            title: 'mission_3',
            text: 'desc_maission_3',
        },
        {
            icon: 'icon4.svg',
            title: 'mission_4',
            text: 'desc_maission_4',
        },

    ]
}

export const getPortailsKayloo = () => {
    return [
        /*{
            image: 'portail-1.png',
            title: 'portail_1',
        },*/
        {
            image: 'locataire.png',
            title: 'portail_2',
        },
        {
            image: 'bailleur.png',
            title: 'portail_3',
        },
        {
            image: 'syndic.png',
            title: 'portail_4',
        },

    ]
}

export const getModulesKayloo = () => {
    return [
        {
            icon: 'module1.svg',
            title: 'module_1',
        },
        {
            icon: 'module2.svg',
            title: 'module_2',
        },
        {
            icon: 'module2.svg',
            title: 'module_3',
        },
        {
            icon: 'module2.svg',
            title: 'module_4',
        },
        {
            icon: 'module5.svg',
            title: 'module_5',
        },
        {
            icon: 'module6.svg',
            title: 'module_6',
        },
        {
            icon: 'module3.svg',
            title: 'module_8',
        },
        {
            icon: 'module7.svg',
            title: 'module_7',
        },
    ]
}

export const getSocials = () => {
    return [
        {
            name: "Facebook",
            href: "https://www.facebook.com/Sepro-Holding-116476687720526",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: "LinkedIn",
            href: "https://bit.ly/3wCwcA9",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 50 50" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z"/>
                    clipRule="evenodd"
                </svg>
            ),
        },
        {
            name: "Twitter",
            href: "https://twitter.com/SeproHolding?t=T8Zc8B5k5JfBJwwB3fSBmQ&s=08",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>
                </svg>
            ),
        },
        {
            name: "Instagram",
            href: "https://instagram.com/seproholding?igshid=YmMyMTA2M2Y=",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ]
}

export const getModulesAthena = () => {
    return [
        {
            icon: 'module1.svg',
            title: 'module_athena_1',
        },
        {
            icon: 'module2.svg',
            title: 'module_athena_2',
        },
        {
            icon: 'module3.svg',
            title: 'module_athena_3',
        },
        {
            icon: 'module4.svg',
            title: 'module_athena_4',
        },
        {
            icon: 'module5.svg',
            title: 'module_athena_5',
        },
        {
            icon: 'module6.svg',
            title: 'module_athena_6',
        },
        {
            icon: 'module7.svg',
            title: 'module_athena_7',
        },
        {
            icon: 'module8.svg',
            title: 'module_athena_8',
        },
    ]
}

export const getPortailsERP = () => {
    return [
        {
            image: 'portail-rh-1.png',
            title: 'portail_rh_1',
        },
        {
            image: 'portail-rh-2.png',
            title: 'portail_rh_2',
        },
        {
            image: 'portail-rh-3.png',
            title: 'portail_rh_3',
        },
        {
            image: 'portail-rh-4.png',
            title: 'portail_rh_4',
        },

    ]
}

export const getMissionsSepro = () => {
    return [
        {
            icon: 'mission_1.png',
            title: 'sepro_mission_1',
        },
        {
            icon: 'mission_2.png',
            title: 'sepro_mission_2',
        },
        {
            icon: 'mission_3.png',
            title: 'sepro_mission_3',
        },
        {
            icon: 'mission_4.png',
            title: 'sepro_mission_4',
        },
        {
            icon: 'mission_5.png',
            title: 'sepro_mission_5',
        },
        {
            icon: 'mission_6.png',
            title: 'sepro_mission_6',
        },
    ]
}


export const getValeursSepro = () => {
    return [
        {
            icon: 'qui_sommes_nous/valeur_1.png',
            title: 'sepro_valeur_1',
        },
        {
            icon: 'qui_sommes_nous/valeur_2.png',
            title: 'sepro_valeur_2',
        },
        {
            icon: 'qui_sommes_nous/valeur_3.png',
            title: 'sepro_valeur_3',
        },
        {
            icon: 'qui_sommes_nous/valeur_4.png',
            title: 'sepro_valeur_4',
        },
        {
            icon: 'qui_sommes_nous/valeur_5.png',
            title: 'sepro_valeur_5',
        },
        {
            icon: 'qui_sommes_nous/valeur_6.png',
            title: 'sepro_valeur_6',
        },
    ]
}


export const getDemarchesSepro = () => {
    return [

        {
            title: 'started',
            points: [
                'started_1',
                'started_2',
                'started_3',
            ]
        },

        {
            title: 'analyse',
            points: [
                'analyse_1',
                'analyse_2',
                'analyse_3',
            ]
        },
        {
            title: 'agile',
            points: [
                'agile_1',
                'agile_2',
                'agile_3',
                'agile_4',
                'agile_5',
                'agile_6',
                'agile_7',
            ]
        },
        {
            title: 'integration',
            points: [
                'integration_1',
            ]
        },
        {
            title: 'formation',
            points: [
                'formation_1',
                'formation_2',
            ]
        },
        {
            title: 'test',
            points: [
                'test_1',
                'test_2',
            ]
        },
        {
            title: 'golive',
            points: [
                'golive_1',
                'golive_2',
            ]
        },
    ]
}